import React from "react"
import {useWindowSize,useMouseMove,useTouchStart,useMouseDown} from './useEventListener.js' // , 
import drawNow from '../js/draw'

export const Canvas = (props) => {
	
	const canref = React.useRef(); // canvas ref
	const size = useWindowSize();
	
	let isTouch = 'ontouchstart' in global;
	
	const mousemove = useMouseMove(isTouch);
	const mousedown = useMouseDown(isTouch);
	
	const touches = useTouchStart();
	
	React.useEffect(() => {
		const getPixelRatio = context => {
			var backingStore =
				context.backingStorePixelRatio ||
				context.webkitBackingStorePixelRatio ||
				context.mozBackingStorePixelRatio ||
				context.msBackingStorePixelRatio ||
				context.oBackingStorePixelRatio ||
				context.backingStorePixelRatio ||
				1;
				
			return (global.devicePixelRatio || 1) / backingStore;
		};
		
		let canvas = canref.current;
		let context = canvas.getContext('2d');
		
		let ratio = getPixelRatio(context);

		const width = size.width;
		const height = size.height;
		
		canvas.width = width*ratio;
		canvas.height = height*ratio;
		
		const animate = () => {
			animationFrameId = requestAnimationFrame(animate);
			drawNow(
				context,
				width,
				height,
				isTouch, // doesn't update but needed in draw (once a touch device, always a touch device)
				mousemove.current,
				touches.current,
				mousedown.current,
				props.loc,
				ratio
			);
		};
		
		let animationFrameId = requestAnimationFrame(animate);
		return () => {console.log('remount'); cancelAnimationFrame(animationFrameId);}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [size]); // remounts animation on resize

	return (
		
			<canvas
			ref={canref}
			style={{
				width: '100%',
				height: '100%',
			}}
		/>
		
	);
};