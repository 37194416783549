import {  useState, useRef, useEffect  } from "react"; 

// useRef versions ///////////////////////
export const useMouseDown = (isTouch) => {

	const mouseDown = useRef(false);

	useEffect(
		() => {
			function handleDown(e) {
				if (isTouch) {return false;}
				mouseDown.current = true;
			}
			function handleUp(e) {
				if (isTouch) {return false;}
				mouseDown.current = false;
			}
			global.addEventListener('mousedown', handleDown);
			global.addEventListener('mouseup', handleUp);
			return () => {
				global.removeEventListener('mousedown', handleDown);
				global.addEventListener('mouseup', handleUp);
			};
		}
	);
	return mouseDown;
};

export const useMouseMove = (isTouch) => {
	function getCoords(clientX,clientY) {
		if (isTouch) {return false;}
		
		return {
			x: clientX || -1,
			y: clientY || -1
		};
		
	}

	const coords = useRef(getCoords());

	useEffect(
		() => {
			function handleMove(e) {
				coords.current = getCoords(e.clientX,e.clientY);
			}
			global.addEventListener('mousemove', handleMove);
			return () => {
				global.removeEventListener('mousemove', handleMove);
			};
		}
	);
	return coords;
};

export const useTouchStart = () => {
	function getTouches(touches) {
		if (typeof touches == 'undefined') {return false;}
		
		let mousePos = [];
		for (let mm=0; mm<touches.length; mm++) {
			mousePos[mm] = {
				"x":touches[mm].pageX,
				"y":touches[mm].pageY,
				"rad":50
			}
		}
		
		return mousePos;
	}

	const touches = useRef(getTouches());

	useEffect(
		() => {
			function handleTouch(e) {
				touches.current = getTouches(e.touches);
			}
			global.addEventListener('touchstart', handleTouch);
			return () => {
				global.removeEventListener('touchstart', handleTouch);
			};
		}
	);
	return touches;
};

/*
export const useWindowSize = () => {
	function getSize() {
		return {
			width: global.innerWidth || 300,
 			height: global.innerHeight || 300
		};
	}
	
	const windowSize = useRef(getSize());

	useEffect(
		() => {
			function handleResize(e) {
				windowSize.current = getSize();
			}
			global.addEventListener('resize', handleResize);
			return () => {
				global.removeEventListener('resize', handleResize);
			};
		}
	);

 	return windowSize;
};
*/


/// useState versions /////////////////
/*
function getCoords(clientX,clientY) {
		return {
			x: clientX || 0,
			y: clientY || 0
		};
	}
	
	const [coords, setCoords] = React.useState(getCoords);

	React.useEffect(
		() => {
			function handleMove(e) {
				setCoords(getCoords(e.clientX,e.clientY));
			}
			global.addEventListener('mousemove', handleMove);
			return () => {
				global.removeEventListener('mousemove', handleMove);
			};
		}
	);
*/

export const useWindowSize = () => {
	function getSize() {
		return {
			width: global.innerWidth,
 			height: global.innerHeight
		};
	}
	
	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(
		() => {
			function handleResize(e) {
				setWindowSize(getSize());
			}
			global.addEventListener('resize', handleResize);
			return () => {
				global.removeEventListener('resize', handleResize);
			};
		}
	);

 	return windowSize;
};



// a general hook
// https://usehooks.com/
// https://github.com/donavon/use-event-listener/blob/develop/src/index.js

// const useEventListener = (eventName, handler, element = global) => {
// 	const savedHandler = useRef();
// 
// 	useEffect(() => {
// 		savedHandler.current = handler;
// 	}, [handler]);
// 
// 	useEffect(
// 		() => {
// 			const isSupported = element && element.addEventListener;
// 			if (!isSupported) return;
// 
// 			const eventListener = event => savedHandler.current(event);
// 			element.addEventListener(eventName, eventListener);
// 			return () => {
// 				element.removeEventListener(eventName, eventListener);
// 			};
// 		},
// 		[eventName, element]
// 	);
// };

// use case
// export const useMouseMove = () => {
// 	
// 	function getCoords(clientX,clientY) {
// 		return {
// 			x: clientX || 0,
// 			y: clientY || 0
// 		};
// 	}
// 	
// 	const [coords, setCoords] = useState(getCoords);
// 
// 	useEventListener('mousemove', ({ clientX, clientY }) => {
// 		setCoords(getCoords(clientX,clientY));
// 	});
// 	return coords;
// };
