let spacials = 100;

let mousePos = [{
		"x":-1,"y":-1,"rad":-1
	}];

let dim = {
	"w":300, "h":300, "min":300
};

let proj = {
	"cnt":[0,0,0],
	"mx":[parseInt(50*(1 + Math.random())),parseInt(100*(1 + Math.random())),parseInt(200*(1 + Math.random()))],
	"var":[100,200,400],
	"tch":0
};

let loclast = -1;

var trendDefault;

function calcDefaultTrend() {
	var widthCnt, 
		widthDIV = dim['w'];
		widthDIV /= spacials;	
	
	trendDefault = {};
	trendDefault['subPx'] = [];
	trendDefault['subPy'] = [];
	widthCnt = 0;
	for (let it=0;it<spacials;it=it+1) {
		trendDefault['subPx'][it] = widthCnt;
		widthCnt += widthDIV;
		trendDefault['subPy'][it] = dim['h']*Math.random();
	}
}

function displayDefaultTrend(context,col) {
	let sectLength = 8;
	
	let randX = [[]];
	let randY = [[]];
	
	let cnt=0;
	let it=0;
	for (let sp=0; sp<spacials; sp++) {
		// random point in circle
		// http://stackoverflow.com/a/5838055/1467200
		let r,u,t;
		t = 2*Math.PI*Math.random();
		u = Math.random()+Math.random();//Math.random()+Math.random();
		
		if (u>1) {r = 2-u;}
		else {r=u;}
		
		randX[it][cnt] = dim['w']*r*Math.cos(t) + 0.25*dim['w'];
		randY[it][cnt] = dim['h']*r*Math.sin(t) - 0.25*dim['h'];
		
		cnt++;
		if (cnt>sectLength) {
			cnt=0; it++;
			randX[it] = [];
			randY[it] = [];
		}
	}
	
	let magnitude = 1.5;
	
	let marked = [];
	for (let it=0; it<randX.length; it++) {
		marked[it] = [];
		for (let ii=0; ii<randX[it].length; ii++) {
			marked[it][ii] = false;
		}
	}
	for (let mm=0; mm<mousePos.length; mm++) {
		for (let it=0; it<randX.length; it++) {
			for (let ii=0; ii<randX[it].length; ii++) {
				
				let dx1proj = trendDefault['subPx'][it]+randX[it][ii];
				let dx2proj = trendDefault['subPy'][it]+randY[it][ii];
				
				// check if dx position is within any clearing radius
				let distX = dx1proj-mousePos[mm].x;
				let distY = dx2proj-mousePos[mm].y;
	
				let noiseRad = Math.sqrt(distX*distX + distY*distY);
	
				// determine edge position
				if (noiseRad < mousePos[mm].rad) {
					let ang = Math.atan2(distY,distX);
		
					let dx1proj_a = parseInt(mousePos[mm].x + mousePos[mm].rad*Math.cos(ang) + 0.5);
					let dx2proj_a = parseInt(mousePos[mm].y + mousePos[mm].rad*Math.sin(ang) + 0.5);
				
					context.globalAlpha = 1;
					magnitude = 2*dim['ratio'];
					context.fillStyle = col;//'#eef';
					context.strokeStyle = col;//'#fff';//col;//'#eef';
					context.lineWidth = dim['ratio'];
				
					context.beginPath();
					context.moveTo((dx1proj_a*dim['ratio'] + magnitude), dx2proj_a*dim['ratio']);
					context.arc(dx1proj_a*dim['ratio'], dx2proj_a*dim['ratio'], magnitude, 0, 2*Math.PI,true);
					context.fill();
					context.closePath();
					
					context.globalAlpha = 0.1;
					
					context.beginPath();
					context.moveTo(dx1proj*dim['ratio'], dx2proj*dim['ratio']);
					context.lineTo(dx1proj_a*dim['ratio'], dx2proj_a*dim['ratio']);
					context.stroke();
					context.closePath();
					
					marked[it][ii] = true;
				}
				if (marked[it][ii]) {continue;}
			} // end ii
		} // end it
	} // end mm
	
	context.globalAlpha = 1;
	context.beginPath();
	context.fillStyle = col;
	for (let it=0; it<randX.length; it++) {
		for (let ii=0; ii<randX[it].length; ii++) {
			if (marked[it][ii]) {continue;}
			
			magnitude = dim['ratio'];
			
			let dx1proj = trendDefault['subPx'][it]+randX[it][ii];
			let dx2proj = trendDefault['subPy'][it]+randY[it][ii];
			
			context.moveTo((dx1proj*dim['ratio'] + magnitude), dx2proj*dim['ratio']);
			context.arc(dx1proj*dim['ratio'], dx2proj*dim['ratio'], magnitude, 0, 2*Math.PI,true);
		}
	}
	context.fill();
	context.closePath();
	
}

const drawNow = (context,width,height,isTouch,mousemove,touches,mouseDown,loc,ratio) => {
	
	if (loc === 'main' || loc === 'about') {
		if (isTouch) { 
			if (touches.length > 0) { mousePos = touches; }
		}
		else {
			if (mousePos.length > 1) {mousePos.splice(1);}
			mousePos[0].x = mousemove.x;
			mousePos[0].y = mousemove.y;
		
			
		}
	}
	//else {return;}
	
	if (dim['w'] !== width || dim['h'] !== height) {
		dim['w'] = width;
		dim['h'] = height;
		dim['max'] = Math.max(dim['w'],dim['h']);
		dim['min'] = Math.min(dim['w'],dim['h']);
		dim['rad'] = {
			"mx":1.5*dim['max'],//0.375*dim['min'],
			"stp":0
		}
		
		dim['ratio'] = ratio;
		
		spacials = parseInt(0.002*dim['w']*dim['h']);
		if (spacials > 9999) {spacials = 9999;}
		
		calcDefaultTrend();
	}
	
	
	
	let col = '#F4B54F';//'#4397AC';
	if (loc === 'main' || loc === 'about') {
	
		context.globalAlpha = 0.05;
		context.fillStyle = 'black';
		context.fillRect(0, 0, width*dim['ratio'], height*dim['ratio']);
	
		if (loc === 'about') {col = '#756449';}//'#3b5033';} // #729FB0 // darker blue
		else {col = '#BDEAFC';}//'#F4B54F';} //'#00FFFF';}   // original #42F5E9

		// #BDEAFC chalky blue: 

		
		if (mousePos[0].rad === -1 || mouseDown) {
			mousePos[0].rad = 0.08*dim['rad']['mx'];
		}
		if (mousePos[0].x === -1) {
			mousePos[0].x = 0.33*dim['w'];
		}
		if (mousePos[0].y === -1) {
			mousePos[0].y = 0.5*dim['h'];
		}
			
		if (loc === 'main') {
			if (loclast !== loc) {
				for (let mm=0; mm<mousePos.length; mm++) {
					mousePos[mm].rad = 0.08*dim['rad']['mx'];
				}
			}
			if (!mouseDown) {
				for (let mm=0; mm<mousePos.length; mm++) {
					if (mousePos[mm].rad<0.09*dim['rad']['mx']) {mousePos[mm].rad *= 1.005;}
				}
			}
		}
		else {
			for (let mm=0; mm<mousePos.length; mm++) {
				if (mousePos[mm].rad<dim['rad']['mx']) {mousePos[mm].rad = dim['rad']['mx'];}
			}
		}
		
		displayDefaultTrend(context,col);
	}
	else {
		
		context.globalAlpha = 0.2;
		context.fillStyle = '#170202';//'#070101';//'black';
		context.fillRect(0, 0, width*dim['ratio'], height*dim['ratio']);
		
		context.save();
		context.shadowColor = col;
		context.shadowBlur = 20;
		context.strokeStyle = col;
		context.beginPath();
		
		for (let i=0; i<proj['cnt'].length; i++) {
			proj['cnt'][i] ++;
			if (proj['cnt'][i] > proj['mx'][i]) {
				proj['cnt'][i] = 0;
				proj['mx'][i] = parseInt(proj['var'][i]*(1+Math.random()));
			
				let radd = dim['rad']['mx'];
				let angrnd1 = 2*Math.random()*Math.PI;
				let angrnd2 = angrnd1 + Math.random()*Math.PI;
				let xx1 = 0.5*dim['w'] + radd*Math.cos(angrnd1);
				let yy1 = 0.5*dim['h'] + radd*Math.sin(angrnd1);
			
				let xx2 = 0.5*dim['w'] + radd*Math.cos(angrnd2);
				let yy2 = 0.5*dim['h'] + radd*Math.sin(angrnd2);
			
				context.globalAlpha = 0.6;
			
				context.lineWidth = 1+5*Math.random();
				context.moveTo(dim['ratio']*xx1, dim['ratio']*yy1);
				context.lineTo(dim['ratio']*xx2, dim['ratio']*yy2);
				
				
			}
		
		}
		
		context.fill();
		context.stroke();
		context.closePath();
		
		context.restore();
		
	}
	
	
	
	loclast = loc;
}

export default drawNow;