import React from "react"

import "@fontsource/jetbrains-mono"

import {Canvas} from './setCanvas.js'
import "./layout.scss"

const Layout = ({ children,location }) => (
	<div>
		<div style={{position:'fixed'}}>
			<Canvas loc={location}/>
		</div>
		<div style={{position:'relative',width:'100%'}}>
			<div style={{height:'100%', display:'flex', flexDirection:"column"}}>
				<main style={{flexGrow: 1}} >
					{children}
					<div className={"spacer s2"}></div>
				</main>
			</div>
		</div>
	</div>
)

export default Layout;