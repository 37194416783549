import React from "react"
import { Link } from "gatsby"

// import definitions from '../js/definitions.json';
// import External from '../assets/external_link.svg';

// const ExternalLink = ({link,site}) => {
// 	return link ?
// 		(<a className="externalLink" href={link}>
//  				<div className="ext1">Visit {site}</div>
//  				<External/>
// 		</a>) : <div></div>
// }
// 
// export const Definition = ({lookup}) => {
// 	return definitions[lookup] ? (
// 		<div>
// 			<p>
// 				{definitions[lookup]['description']}
// 			</p>
// 			<ExternalLink link={definitions[lookup]['link']} site={lookup}/>
// 			<div className={"spacer s1"}></div>
// 		</div>
// 	) : <div className={"spacer s1"}></div>
// }

export const makeName = (name) => {
	const name_arr = name.split(',');
	if (name_arr.length === 1) {return name;}
	else { return name_arr[1] + ' ' + name_arr[0] }
}

export const MainList = (props) => {
	return (
		<section>
			<ul className={"tagList"}>
				<Link key={"toHome"} to={`/`}>
					<li className={props.main ? "active" : ""}>
						Owen Fernley
					</li>
				</Link>
				<Link key={"toAbout"} to={`/about/`}>
					<li className={props.about ? "active" : ""}>
						About
					</li>
				</Link>
				<Link key={"toProj"} to={`/projects/`}>
					<li className={props.project ? "active" : ""}>
						Projects ({props.total})
					</li>
				</Link>
				<Link key={"toTalk"} to={`/talks/`}>
					<li className={props.talk ? "active" : ""}>
						Selected Talks ({props.totalTalk})
					</li>
				</Link>
			</ul>
		</section>
	) 
}

export const CatList = (props) => {
	return (
		<section>
			<ul className={"tagList"}>
				<Link key={"toCat"} to={`/categories/`}>
					<li className={props.category ? "active" : ""}>
						Categories
					</li>
				</Link>
				<Link key={"toCollab"} to={`/collaborations/`}>
					<li className={props.collaboration ? "active" : ""}>
						Collaborations
					</li>
				</Link>
				<Link key={"toTech"} to={`/technology/`}>
					<li className={props.technology ? "active" : ""}>
						Technology
					</li>
				</Link>
			</ul>
		</section>
	)
}